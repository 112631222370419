import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

import Constants from "../constants";

const defaultKeywords = [
  `adventure travel blog europe`,
  `long term travel`,
  `active travel`,
  `europe backpacking trip`,
  `sheila murray`,
  `james simone`,
  `travel blog`,
  `traveling on a budget`
];

const getArticleMetadata = ({
  createdDate,
  mainEntityOfPage,
  modifiedDate,
  isBlogPost,
  keywords
}) => {
  const metadata = [];
  if (!isBlogPost) {
    return metadata;
  }
  metadata.push(
    {
      name: `article:publisher`,
      content: `${Constants.baseUrl}/`,
      itemprop: `publisher`,
      itemtype: "https://schema.org/Organization",
      itemscope: "true"
    },
    {
      name: `article:section`,
      itemprop: "articleSection",
      content: `Sustainable Travel & Vegan / Vegetarian Travel Guide`
    },
    {
      name: "article:published",
      content: createdDate,
      itemprop: "datePublished dateCreated"
    },
    {
      name: "article:modified",
      content: modifiedDate,
      itemprop: "dateModified"
    },
    {
      name: "mainEntityOfPage",
      itemscope: "true",
      itemProp: "mainEntityOfPage",
      content: mainEntityOfPage,
      itemtype: "https://schema.org/WebPage"
    }
  );
  metadata.concat(
    keywords.length > 0
      ? keywords.map(keyword => ({
          name: `article:tag`,
          content: keyword
        }))
      : defaultKeywords.map(defaultKeyword => ({
          name: `article:tag`,
          content: defaultKeyword
        }))
  );
  return metadata;
};

const SEO = ({
  avatar,
  description,
  logo,
  isHomePage,
  postData = { postData: ``, keywords: [], author: `Sheila Murray` },
  site,
  title
}) => {
  const { author, isBlogPost, image, imageAlt, keywords } = postData;
  const seoKeywords =
    keywords.length > 0 ? keywords.join(`, `) : defaultKeywords.join(`, `);

  const metaDescription = description || site.siteMetadata.description;
  const siteUrl =
    process.env.NODE_ENV === "development"
      ? "localhost:8000"
      : site.siteMetadata.siteUrl;
  const ogImage = siteUrl + (image ? image : avatar.childImageSharp.fixed.src);

  const schemaGraph = [
    {
      "@type": "Organization",
      "@id": `${site.siteMetadata.siteUrl}/#organization`,
      name: "She & Jim",
      url: site.siteMetadata.siteUrl,
      sameAs: [
        "https://instagram.com/mrwordsmith",
        "https://instagram.com/sheflowsandgrows"
      ],
      logo: {
        "@type": "ImageObject",
        "@id": `${site.siteMetadata.siteUrl}/#logo`,
        url: siteUrl + logo.childImageSharp.original.src,
        caption:
          "Enjoying a much-deserved rest while backpacking through Europe."
      },
      image: {
        "@id": `${site.siteMetadata.siteUrl}/#logo`
      }
    },

    {
      "@type": "WebSite",
      "@id": `${site.siteMetadata.siteUrl}/#website`,
      url: `${site.siteMetadata.siteUrl}`,
      name: "She & Jim",
      publisher: {
        "@id": `${site.siteMetadata.siteUrl}/#organization`
      },
      potentialAction: {
        "@type": "SearchAction",
        target: `${site.siteMetadata.siteUrl}/blog/?keywords={search_term_string}`,
        "query-input": "required name=search_term_string"
      }
    }
  ];

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": `${site.siteMetadata.siteUrl}/#webpage`,
        name: "Homepage"
      },
      position: 1
    }
  ];

  if (isBlogPost) {
    const article = {
      "@context": "https://schema.org",
      "@type": "Article",
      "@id": `${site.siteMetadata.siteUrl + postData.url}#article`,
      author: {
        "@type": "Person",
        name: author
      },
      copyrightHolder: {
        "@type": "Person",
        name: author
      },
      copyrightYear: new Date().getFullYear(),
      creator: {
        "@type": "Person",
        name: author
      },
      publisher: { "@id": `${site.siteMetadata.siteUrl}/#organization` },
      datePublished: postData.createdDate,
      dateModified: postData.modifiedDate,
      description: metaDescription,
      headline: title,
      articleBody: metaDescription,
      articleSection: seoKeywords,
      inLanguage: `en-US`,
      url: site.siteMetadata.siteUrl + postData.url,
      name: title,
      image: {
        "@id": `${site.siteMetadata.siteUrl + postData.url}#primaryimage`
      },
      mainEntityOfPage: {
        "@id": `${site.siteMetadata.siteUrl + postData.url}#webpage`
      },
      keywords: seoKeywords,
      isPartOf: {
        "@id": `${site.siteMetadata.siteUrl + postData.url}#webpage`
      }
    };
    schemaGraph.push([
      article,
      {
        "@type": "ImageObject",
        "@id": `${site.siteMetadata.siteUrl + postData.url}#primaryimage`,
        url: ogImage,
        caption: imageAlt
      },

      {
        "@type": "WebPage",
        "@id": `${site.siteMetadata.siteUrl + postData.url}#webpage`,
        url: `${site.siteMetadata.siteUrl + postData.url}`,
        inLanguage: `en-US`,
        name: title,
        isPartOf: { "@id": `${site.siteMetadata.siteUrl}/#website` },
        about: { "@id": `${site.siteMetadata.siteUrl}/#organization` },
        primaryimageOfPage: {
          "@id": `${site.siteMetadata.siteUrl + postData.url}#primaryimage`
        },
        datePublished: "2019-04-02T10:30:00+00:00",
        dateModified: site.buildTime
      }
    ]);
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: article,
      position: 2
    });
  } else {
    schemaGraph.push([
      {
        "@type": "ImageObject",
        "@id": `${site.siteMetadata.siteUrl}/#primaryimage`,
        url: ogImage,
        caption: "James Simone & Sheila Murray traveling the world"
      },

      {
        "@type": "WebPage",
        "@id": `${site.siteMetadata.siteUrl}/#webpage`,
        url: `${site.siteMetadata.siteUrl}/`,
        inLanguage: `en-US`,
        name: title,
        isPartOf: { "@id": `${site.siteMetadata.siteUrl}/#website` },
        about: { "@id": `${site.siteMetadata.siteUrl}/#organization` },
        headline: site.siteMetadata.subTitle,
        mainEntityOfPage: site.siteMetadata.siteUrl,
        description: site.siteMetadata.description,
        primaryimageOfPage: { "@id": `${site.siteMetadata.siteUrl}/#logo` },
        datePublished: "2019-04-02T10:30:00+00:00",
        dateModified: site.buildTime,
        keywords: seoKeywords
      }
    ]);
  }

  const breadcrumb = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "@id": `${site.siteMetadata.siteUrl}/#breadcrumb`,
    description: "Breadcrumbs list",
    name: "She & Jim Breadcrumbs",
    itemListElement
  };

  schemaGraph.push(breadcrumb);

  const schemaOrgWebPage = {
    "@context": "https://schema.org",
    "@graph": schemaGraph
  };

  return useMemo(
    () => (
      <Helmet
        htmlAttributes={{ lang: `en` }}
        title={title}
        titleTemplate={`%s`}
        meta={[
          {
            name: `description`,
            itemprop: `description`,
            content: metaDescription
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:image`,
            content: ogImage
          },
          {
            property: `image`,
            itemprop: `image`,
            content: ogImage,
            itemscope: "true",
            itemtype: "https://schema.org/ImageObject"
          },
          {
            property: `og:image:alt`,
            content: imageAlt
              ? imageAlt
              : "Enjoying a much-deserved rest while backpacking through Europe."
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `description`,
            itemProp: `description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: isBlogPost ? "article" : "website"
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: "mrwordsmith"
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },
          {
            name: `p:domain_verify`,
            content: "b95af375998d6ec84af96586b3e17396"
          }
        ]
          .concat(
            seoKeywords.length > 0
              ? {
                  name: `keywords`,
                  content: seoKeywords
                }
              : []
          )
          .concat(
            getArticleMetadata({
              createdDate: postData.createdDate,
              isBlogPost,
              keywords,
              modifiedDate: postData.modifiedDate,
              mainEntityOfPage: `${site.siteMetadata.siteUrl + postData.url}`
            })
          )}
      >
        {(isHomePage || isBlogPost) && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
      </Helmet>
    ),
    [
      imageAlt,
      isBlogPost,
      isHomePage,
      metaDescription,
      ogImage,
      schemaOrgWebPage,
      seoKeywords,
      title,
      keywords,
      postData.createdDate,
      postData.modifiedDate,
      postData.url,
      site.siteMetadata.siteUrl
    ]
  );
};

export default SEO;
