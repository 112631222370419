const mobileBreakpoint = 768;

const Constants = {
  mobileBreakpoint,
  mobileWidth: mobileBreakpoint + "px",
  Colors: {
    headerTheme: "#3e7598",
    headerGray: "#82849e",
    internalLinks: "#007acc",
    primaryDark: "#4f4f4f",
    primaryLight: "#EFFFFA"
  },
  baseUrl: "https://www.sheandjim.com"
};

module.exports = Constants;
