import { Link } from "gatsby";
import styled from "styled-components";

import Constants from "../constants";

const InternalLink = styled(Link)`
  box-shadow: none;
  color: ${({ color }) => (color ? color : "inherit")};
  display: ${({ display }) => (display ? display : "inline")};
  text-decoration: none;

  :hover {
    color: ${Constants.Colors.headerGray};
  }
`;

export default InternalLink;
