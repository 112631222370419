import Typography from "typography";
import Wordpress2016 from "jamessimone-typography-theme-wordpress-2016";

Wordpress2016.overrideThemeStyles = () => ({
  "a.gatsby-resp-image-link": {
    boxShadow: `none`
  },
  "h1,h2,h3,h4,h5": {
    marginBottom: typography.rhythm(1 / 2),
    marginTop: "1.5rem"
  },
  li: {
    padding: "5px"
  },
  "p, ul": {
    marginBottom: "1rem"
  },
  blockquote: {
    fontSize: "1rem"
  }
});

const typography = new Typography(Wordpress2016);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
