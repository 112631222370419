import React, { useState } from "react";
import styled from "styled-components";

import Backdrop from "./backdrop";
import Burger from "./burger";
import CenteredText from "./centered-text";
import Constants from "../constants";
import InternalLink from "./internal-link";
import { useWindow } from "../hooks/useWindow";

const Header = styled.header`
  background-color: #fff;
  display: flex;
  left: 0;
  letter-spacing: 0.1em;
  justify-content: center;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const StyledNav = styled.nav`
  background-color: #fff;
  position: fixed;
  width: 100%;

  @media (max-width: ${Constants.mobileWidth}) {
    height: 50px;
  }
`;

const SideLinkWrapper = styled.div`
  ${({ location, to }) =>
    location && location.pathname === to
      ? `color: ${Constants.Colors.headerTheme};`
      : ""}
  display: inline;
  font-weight: 600;
  font-size: 12px;

  padding: 0 0 0 15px;

  @media (max-width: ${Constants.mobileWidth}) {
    padding: 0 0 0 5px;
  }
`;

const Headline = styled.h1`
  display: block;
  font-family: Amatic SC;
  font-size: 45px;
  margin: 5px 0;

  :hover {
    color: ${Constants.Colors.headerGray};
  }

  @media (max-width: ${Constants.mobileWidth}) {
    font-size: 40px;
    margin: 0;
    padding: 5px;
  }
`;

const NavLinkList = styled.ol`
  list-style-type: none;
  margin: 0;

  @media (max-width: ${Constants.mobileWidth}) {
    display: ${({ isOpen, show }) =>
      show && isOpen && show ? "block" : "none"};
  }
`;

const NavListItem = styled.li`
  display: inline;
  margin-bottom: 0;
  padding-top: 10px;
`;

const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: ${Constants.Colors.primaryLight};
  height: 100vh;
  text-align: left;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-100%)"};

  @media (max-width: ${Constants.mobileWidth}) {
    width: 100%;
  }

  a {
    display: block;
    padding: 1rem 0;
    font-weight: bold;
    color: ${Constants.Colors.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${Constants.mobileWidth}) {
      font-size: 1rem;
      text-align: center;
    }
  }
`;

const getNavLinkItems = (location, show) => {
  const navLinks = [
    { to: "/blog/", text: "BLOG", title: "Our blog" },
    {
      to: "/recipes/",
      text: "RECIPES",
      title: "Vegetarian, vegan, plant-based recipes"
    },
    {
      to: "/timeline/",
      text: "TIMELINE",
      title: "Timeline of our backpacking trip"
    },
    {
      to: "/chase-us/",
      text: "SUBSCRIBE / CONTACT US",
      title: "Subscribe and contact us"
    },
    { to: "/about-us/", text: "ABOUT US", title: "About us" }
  ];
  show && location.pathname !== "/" && navLinks.push({ to: "/", text: "HOME" });
  return navLinks.map((navLink, index) => (
    <NavListItem location={location} key={navLink.to}>
      <SideLinkWrapper location={location} to={navLink.to}>
        <InternalLink
          to={navLink.to}
          aria-current={
            location && location.pathname === navLink.to ? "true" : null
          }
          title={navLink.title}
        >
          {navLink.text}{" "}
        </InternalLink>
      </SideLinkWrapper>{" "}
      {index < navLinks.length - 1 && !show && "."}
    </NavListItem>
  ));
};

const NavHeader = ({ location, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Header>
      <StyledNav>
        {isMobile ? (
          <>
            <Burger isOpen={isOpen} setIsOpen={setIsOpen} />
            <MobileMenu isOpen={isOpen}>
              <NavLinkList id="mobileNavLinks" show={isMobile} isOpen={isOpen}>
                {getNavLinkItems(location, isMobile)}
              </NavLinkList>{" "}
            </MobileMenu>
          </>
        ) : null}
        <CenteredText>
          <Headline>
            <InternalLink
              to="/"
              aria-label="She & Jim logo, click to visit homepage"
              title={`She & Jim homepage`}
            >
              She & Jim
            </InternalLink>
          </Headline>
        </CenteredText>
        {!isMobile && (
          <NavLinkList id="desktopNavLinks">
            {getNavLinkItems(location)}
          </NavLinkList>
        )}
      </StyledNav>
    </Header>
  );
};

const Nav = ({ backdrop, location }) => {
  const { isMobile } = useWindow();
  return (
    <>
      <NavHeader location={location} isMobile={isMobile} />
      <Backdrop backdrop={backdrop} />
    </>
  );
};

export default Nav;
