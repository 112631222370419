import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import styled from "styled-components";

const StyledAnchor = styled(OutboundLink)`
  box-shadow: none;
`;

const ExternalLink = ({ href, text, className, style }) => (
  <StyledAnchor
    href={href}
    target="_blank"
    rel="noopener"
    className={className}
    style={style}
  >
    {text}
  </StyledAnchor>
);

export default ExternalLink;
