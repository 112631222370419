import React from "react";
import styled from "styled-components";

import Bio from "./bio";
import ContentBreak from "./content-break";
import Footer from "./footer";
import Nav from "./nav";
import { rhythm } from "../utils/typography";
import useScrollHandler from "../hooks/useScrollHandler";

const MainWrapper = styled.div`
  @font-face {
    font-family: "Amatic SC";
    font-display: fallback;
    src: local("AmaticSC"),
      url("/Fonts/AmaticSC/AmaticSC.woff2") format("woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Merriweather";
    font-display: fallback;
    src: local("Merriweather-Regular"),
      url("/Fonts/Merriweather/Merriweather-Regular.woff2") format("woff2");
    font-weight: 400;
  }

  background-color: #fff;
  margin: 0 auto 0 auto;
  max-width: ${rhythm(25)};
  padding: ${rhythm(0.5)} ${rhythm(3 / 4)};
  z-index: 100;
`;

const Layout = ({
  avatar,
  backdrop,
  children,
  commentsWrapper,
  location,
  site
}) => {
  useScrollHandler(location);

  return (
    <MainWrapper>
      <Nav backdrop={backdrop} location={location} />
      <ContentBreak margin={`.5rem 0 0 0`} />
      <main>{children}</main>
      <Bio avatar={avatar} commentsWrapper={commentsWrapper} site={site} />
      <ContentBreak />
      <Footer />
    </MainWrapper>
  );
};

export default Layout;
