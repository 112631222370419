import styled from "styled-components";

import Constants from "../constants";

const ContentBreak = styled.hr`
  height: 2px;
  background-color: ${Constants.Colors.headerTheme};
  ${({ margin }) => (margin ? `margin: ${margin}` : ``)}
`;

export default ContentBreak;
