import Image from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";

const StyledBackdrop = styled(Image)`
  display: block;
  margin: 75px 0 0 0;

  @media (max-width: ${Constants.mobileWidth}) {
    margin: 40px 0 0 0;
  }
`;

const Backdrop = ({ backdrop }) => (
  <StyledBackdrop
    fluid={backdrop.childImageSharp.fluid}
    alt="mountains"
    title="It's the rise and fall that makes mountains, after all"
    loading="eager"
    fadeIn={false}
  />
);

export default Backdrop;
