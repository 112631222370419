import { useState, useEffect } from "react";

import Constants from "../constants";

export const useWindow = () => {
  const [windowSize, setWindowSize] = useState(() => {
    return (
      typeof window !== "undefined" && {
        width: window.outerWidth,
        height: window.outerHeight,
        isMobile: window.outerWidth <= Constants.mobileBreakpoint
      }
    );
  });

  useEffect(() => {
    const onResize = () =>
      typeof window !== "undefined" &&
      setWindowSize({
        width: window.outerWidth,
        height: window.outerHeight,
        isMobile: window.outerWidth <= Constants.mobileBreakpoint
      });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize);
    }
    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("resize", onResize);
  });

  return windowSize;
};
