import { useEffect } from "react";

const scrollOptions = {
  behavior: "auto",
  block: "center",
  inline: "start"
};

const genericScrollHandler = event => {
  const element = document.getElementById(event.currentTarget.hash);

  if (element) {
    event.preventDefault();
    element.scrollIntoView(scrollOptions);
  }
};

const scrollHandler = event => {
  const elementId = event.currentTarget.hash.slice(
    1,
    event.currentTarget.hash.length
  );
  const element = document.getElementById(elementId);

  if (element) {
    event.preventDefault();
    element.scrollIntoView(scrollOptions);
  }
};

const attachScrollHandler = hash => {
  const footnotes = document.getElementsByClassName("footnote-ref");
  const bottomFootnotes = document.getElementsByClassName("footnote-backref");
  for (let i = 0; i < footnotes.length; i++) {
    footnotes[i].onclick = scrollHandler;
  }
  for (let i = 0; i < bottomFootnotes.length; i++) {
    bottomFootnotes[i].onclick = scrollHandler;
  }

  let possibleScrollPoint = hash && hash.slice(1, hash.length);
  if (possibleScrollPoint && possibleScrollPoint.endsWith("/")) {
    possibleScrollPoint = possibleScrollPoint.substring(
      0,
      possibleScrollPoint.length - 1
    );
  }

  let possibleScrollElem = document.getElementById(possibleScrollPoint);
  if (!possibleScrollElem) {
    possibleScrollElem = document.getElementById(`#${possibleScrollPoint}`);
  }

  if (possibleScrollElem) {
    possibleScrollElem.scrollIntoView(scrollOptions);
  }
};

const useScrollHandler = location => {
  const hash = location ? location.hash : "";
  return useEffect(() => {
    attachScrollHandler(hash);
  }, [hash]);
};

export default useScrollHandler;
export { genericScrollHandler };
