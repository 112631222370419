import Image from "gatsby-image";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import ExternalLink from "./external-link";
import { rhythm } from "../utils/typography";

const InstaLink = styled(ExternalLink)`
  background-color: ${Constants.Colors.headerTheme};
  color: #fff;
  display: inline-block;
  margin: 1rem 7px 0 0;
  padding: 5px;
  text-decoration: none;

  @media (max-width: ${Constants.mobileWidth}) {
    margin: 0 10px 10px 0;

    &::after {
      content: " →";
    }
  }
`;

const BioWrapper = styled.div`
  display: flex;
  margin: ${rhythm(1)} auto ${rhythm(0)} auto;
`;

const ImageWrapper = styled(Image)`
  border-radius: 75%;
  margin: 0 ${rhythm(1 / 2)} ${rhythm(0)} 0;
  min-width: 50px;
`;

const getInstaLinks = (instagram) => {
  const getInstaLink = (instaSrc, index, text) => (
    <InstaLink
      href={`https://instagram.com/${instaSrc}`}
      key={index}
      text={text}
    />
  );

  const mappedHandles = instagram.map((instaSrc, index) =>
    getInstaLink(instaSrc, index, `Follow @${instaSrc}`)
  );
  mappedHandles.push(
    getInstaLink(
      "explore/tags/travellightexperienceheavy",
      "travel-light",
      "#travellightexperienceheavy"
    )
  );
  return mappedHandles;
};

const Bio = ({
  avatar,
  commentsWrapper,
  site: {
    siteMetadata: {
      authors,
      social: { instagram },
    },
  },
}) => (
  <BioWrapper commentsWrapper={commentsWrapper}>
    <ImageWrapper
      fixed={avatar.childImageSharp.fixed}
      alt="It's us!"
      title={`Sheila Murray & James Simone jumping for joy`}
    />
    <p>
      Adventure travel blog by <strong>{authors}</strong>. Travel along with
      them on their backpacking trip(s) as they actively explore the American
      West, Europe and beyond:
      <br />
      <br />
      {getInstaLinks(instagram)}
    </p>
  </BioWrapper>
);

export default Bio;
