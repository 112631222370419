import React from "react";
import styled from "styled-components";

import Emoji from "./emoji";
import ExternalLink from "./external-link";

const StyledFooter = styled.footer`
  text-align: center;
`;

const Footer = () => (
  <StyledFooter>
    © <span itemProp="copyrightYear">2019</span> - {new Date().getFullYear()}.
    Built with <Emoji symbol="💖" label="love" /> by{" "}
    <ExternalLink href="https://jamessimone.net" text="James" /> and{" "}
    <ExternalLink href="https://www.sheilaanne.com" text="Sheila" />
  </StyledFooter>
);

export default Footer;
